<template>
  <div>
    <app-breadcrumb/>
    <div class="page-container recharge-apply">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="银行汇款" name="bank">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="bankForm" :model="bankApplyForm" :rules="bankApplyRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="bankApplyForm.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(bankApplyForm.money) }}</span>
            </el-form-item>
            <el-form-item label="公司收款账户:" prop="accountId">
              <el-select v-model="bankApplyForm.accountId" placeholder="请选择公司收款账户">
                <el-option v-for="item in accountList" :key="item.id" :label="item.bankName+' '+item.accountNo"
                           :value="item.accountId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="摘要:">
              <el-input v-model="bankApplyForm.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <!--            <el-form-item label="附件:">-->
            <!--              <el-upload-->
            <!--                class="upload-demo"-->
            <!--                :action="uploadUrl"-->
            <!--                :headers="headers"-->
            <!--                :show-file-list="false"-->
            <!--                :on-success="uploadSuccess"-->
            <!--                :before-upload="beforeUpload"-->
            <!--                :on-progress="uploadProcess"-->
            <!--              >-->
            <!--                <div v-if="!uploadLoading && !bankApplyForm.attachment" style="text-align:left;">-->
            <!--                  <el-button size="small">添加附件</el-button>-->
            <!--                  <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>-->
            <!--                </div>-->
            <!--                <el-progress v-if="uploadLoading" :percentage="uploadPercent" />-->
            <!--                <img v-if="!uploadLoading && bankApplyForm.attachment" :src="bankApplyForm.attachment" style="max-width:480px; max-height:120px" />-->
            <!--              </el-upload>-->
            <!--            </el-form-item>-->
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onBankSubmit">保存</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="在线支付" name="online">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="onlineForm" :model="onlineApplyForm" :rules="onlineApplyRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="onlineApplyForm.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(onlineApplyForm.money) }}</span>
            </el-form-item>
            <!--<el-form-item label="支付方式:" prop="payment" style="margin-bottom:20px;">
              <div class="payments">
                <el-radio
                    v-for="item in paymentList"
                    :key="item.payCode"
                    v-model="onlineApplyForm.payment"
                    :label="item.payCode"
                    class="payment-item"
                >
                  <div class="p-image" :class="{active:onlineApplyForm.payment===item.payCode}">
                    &lt;!&ndash;                    <img :src="require('@/assets/images/payment/'+ item.payCode +'.png')">&ndash;&gt;
                    <img v-if="item.payCode==='rongbaok' || item.payCode==='allink'" :src="require('@/assets/images/payment/kuaijiezhifu.jpg')" alt="图片">
                    <img v-else :src="require('@/assets/images/payment/zaixianzhifu.jpg')" alt="图片">
                  </div>
                </el-radio>
              </div>
            </el-form-item>-->
            <el-form-item label="摘要:">
              <el-input v-model="onlineApplyForm.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onOnlineSubmit">保存</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="移动支付" name="scanCode" >
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="scanCodeForm" :model="scanCodeForm" :rules="scanCodeRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="scanCodeForm.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
<!--            <el-form-item label="支付方式:" prop="wxorzfb">-->
<!--              <el-select v-model="scanCodeForm.wxorzfb" placeholder="请选择支付方式">-->
<!--                <el-option label="微信" value="W01"/>-->
<!--                <el-option label="支付宝" value="A01"/>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(scanCodeForm.money) }}</span>
            </el-form-item>
            <el-form-item label="摘要:">
              <el-input v-model="scanCodeForm.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onScanCodeSubmit">生成付款码</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!--<el-tab-pane label="移动支付" name="scanCode2" v-if="allinsmIIShow">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="scanCodeForm2" :model="scanCodeForm2" :rules="scanCodeRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="scanCodeForm2.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
            <el-form-item label="支付方式:" prop="wxorzfb">
              <el-select v-model="scanCodeForm2.wxorzfb" placeholder="请选择支付方式">
&lt;!&ndash;                <el-option label="微信" value="W01"/>&ndash;&gt;
                <el-option label="支付宝" value="A01"/>
                <el-option label="云闪付" value="U01"/>
              </el-select>
            </el-form-item>
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(scanCodeForm2.money) }}</span>
            </el-form-item>
            <el-form-item label="摘要:">
              <el-input v-model="scanCodeForm2.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onScanCodeSubmit2">生成付款码</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>-->
        <!--<el-tab-pane label="移动支付3" name="scanCode3">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="scanCodeForm" :model="scanCodeForm3" :rules="scanCodeRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="scanCodeForm3.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(scanCodeForm3.money) }}</span>
            </el-form-item>
            <el-form-item label="摘要:">
              <el-input v-model="scanCodeForm3.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onScanCodeSubmit3">生成付款码</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>-->
        <el-tab-pane label="现金/POS刷卡" name="pos">
          <div class="pane-title">基本信息</div>
          <el-divider></el-divider>
          <el-alert title="带红色星号“*”部分为必填项" type="info"/>
          <el-form ref="posForm" :model="posApplyForm" :rules="posApplyRules" label-width="140px" size="small">
            <el-form-item label="单据类型:" style="margin-bottom:10px;">
              <span>充值</span>
            </el-form-item>
            <el-form-item label="帐户类型:" style="margin-bottom:10px;">
              <span>账户</span>
            </el-form-item>
            <el-form-item label="申请数额:" prop="money">
              <el-input v-model.trim="posApplyForm.money" placeholder="请填写申请数额"></el-input>
            </el-form-item>
            <el-form-item label="支付金额:" style="margin-bottom:10px;">
              <span class="money">{{ parseNumber(posApplyForm.money) }}</span>
            </el-form-item>
            <el-form-item label="充值方式:" prop="payType">
              <el-select v-model="posApplyForm.payType" placeholder="请选择充值方式">
                <el-option v-for="item in payTypes" :key="item.code" :label="$lt(initDict.type, item.code)"
                           :value="item.code"/>
              </el-select>
            </el-form-item>
            <el-form-item label="摘要:">
              <el-input v-model="posApplyForm.remark" type="textarea" :rows="4" placeholder="请填写摘要" maxlength="100"
                        show-word-limit style="width: 640px"/>
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" @click="onPosSubmit">保存</el-button>
              <el-button type="default" @click="onBack">返回</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
        title="付款二维码"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <div>
        <p style="margin: 0">请使用
<!--          <span style="color: red" v-if="scanCodeForm.wxorzfb==='W01'">微信</span>-->
<!--          <span style="color: red" v-else>支付宝</span>-->
          <span v-if="scanCodeForm2.wxorzfb" style="color: red">
            {{ scanCodeForm2.wxorzfb === 'A01' ? '支付宝' : '云闪付'}}
          </span>
          <!--<span v-else style="color: red">微信或支付宝</span>-->
          <span v-else style="color: red">支付宝</span>
          进行支付</p>
        <vue-qr :text="imageUrl" :size="200"></vue-qr>
        <p style="margin: 0;color: red">注：支付完成后请点击支付完成按钮，切勿重复支付。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toClose" style="margin-right: 30px">取 消</el-button>
        <el-button type="primary" @click="payDone">已完成支付</el-button>
      </span>
    </el-dialog>
    <pay-online-form ref="payOnlineForm" :data="payOnlineData"/>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth'
import {num2string} from '@/utils/num2string'
import {fetchBankAccounts, applyRecharge, onlineRecharge} from '@/api/account/recharge'
import PayOnlineForm from '@/components/PayOnlineForm'
import {accountRechargeSaoma} from "@/api/order"
import vueQr from 'vue-qr'

export default {
  name: 'AccountRechargeApply',
  components: {PayOnlineForm,vueQr},
  data() {
    return {
      imageUrl: '',
      // allinsmShow: false,
      allinsmIIShow: true,
      dialogVisible: false,
      activeTab: 'scanCode',
      uploadUrl: process.env.VUE_APP_BASE_API + '/upload/picture',
      headers: {
        'Authorization': getToken()
      },
      uploadLoading: false,
      uploadPercent: 0,
      bankApplyForm: {
        payType: 0,
        money: undefined,
        accountId: undefined,
        remark: undefined,
        attachment: undefined
      },
      accountList: [],
      paymentList: [],
      loading: false,
      bankApplyRules: {
        money: [
          {required: true, message: '请填写申请数额', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的格式，可保留两位小数',
            trigger: 'blur'
          }
        ],
        accountId: [{required: true, message: '请选择公司收款账户', trigger: 'change'}]
      },
      posApplyForm: {
        money: undefined,
        payType: undefined,
        remark: undefined
      },
      scanCodeForm: {
        money: undefined,
        wxorzfb: undefined,
        payType: undefined,
        remark: undefined
      },
      scanCodeForm2: {
        money: undefined,
        payType: undefined,
        remark: undefined
      },
      scanCodeForm3: {
        money: undefined,
        payType: undefined,
        remark: undefined
      },
      scanCodeRules: {
        money: [
          {required: true, message: '请填写申请数额', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的金额格式，可保留两位小数',
            trigger: 'blur'
          }
        ],
        wxorzfb: [{required: true, message: '请选择支付方式', trigger: 'change'}]
      },
      posApplyRules: {
        money: [
          {required: true, message: '请填写申请数额', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的金额格式，可保留两位小数',
            trigger: 'blur'
          }
        ],
        payType: [{required: true, message: '请选择充值方式', trigger: 'change'}]
      },
      onlineApplyForm: {
        payType: 1,
        money: undefined,
        payment: undefined,
        remark: undefined
      },
      onlineApplyRules: {
        money: [
          {required: true, message: '请填写申请数额', trigger: 'blur'},
          {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '请填写正确的金额格式，可保留两位小数',
            trigger: 'blur'
          }
        ],
        payment: [{required: true, message: '请选择支付方式', trigger: 'change'}]
      },
      payOnlineData: undefined,
      initDict: {
        type: 'trans_type_cn'
      }
    }
  },
  computed: {
    payTypes() {
      return this.$ll(this.initDict.type).filter(ele => ele.code === '2' || ele.code === '16' || ele.code === '17')
    }
  },
  mounted() {
    this.getBankAccounts()
    // this.getPayments()
  },
  methods: {
    getBankAccounts() {
      fetchBankAccounts().then(res => {
        this.accountList = res.data
      })
    },
    // getPayments() {
    //   fetchPayments().then(res => {
    //     // 判断是否存在 allinsm 和 allinsmII 移动支付
    //     let resData = res.data;
    //     for (let i = 0; i < resData.length; i++) {
    //       if (resData[i].payCode === 'allinsm' || resData[i].payCode === 'allinsmII') {
    //         if (resData[i].payCode === 'allinsm') {
    //           this.allinsmShow = true
    //         } else if (resData[i].payCode === 'allinsmII') {
    //           this.allinsmIIShow = true
    //         }
    //       } else {
    //         this.paymentList.push(resData[i])
    //       }
    //     }
    //   })
    // },
    onBack() {
      this.$router.go(-1)
    },
    onBankSubmit() {
      this.$refs['bankForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          applyRecharge(this.bankApplyForm).then(() => {
            this.$message.success('申请成功，请等待管理员审核')
            this.$router.replace('/account/recharge')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onOnlineSubmit() {
      this.$refs['onlineForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          onlineRecharge(this.onlineApplyForm).then(res => {
            this.payOnlineData = res.data
            this.$nextTick(() => {
              this.$refs['payOnlineForm'].submit()
            })
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onPosSubmit() {
      this.$refs['posForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          applyRecharge(this.posApplyForm).then(() => {
            this.$message.success('申请成功，请等待管理员审核')
            this.$router.replace('/account/recharge')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onScanCodeSubmit() {
      this.$refs['scanCodeForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.scanCodeForm.payType = 1
          this.scanCodeForm.saomatype  = 0
          accountRechargeSaoma(this.scanCodeForm).then((res) => {
            console.log(res.data)
            this.imageUrl = res.data
            this.dialogVisible = true
            // this.$message.success('申请成功，请等待管理员审核')
            // this.$router.replace('/account/recharge')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    onScanCodeSubmit2() {
      this.$refs['scanCodeForm2'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.scanCodeForm2.payType = 1
          this.scanCodeForm2.saomatype = 1
          accountRechargeSaoma(this.scanCodeForm2).then((res) => {
            console.log(res.data)
            this.imageUrl = res.data
            this.dialogVisible = true
            // this.$message.success('申请成功，请等待管理员审核')
            // this.$router.replace('/account/recharge')
            this.loading = false
          }).catch(err => {
            console.warn(err)
            this.loading = false
          })
        }
      })
    },
    // onScanCodeSubmit3() {
    //   this.$refs['scanCodeForm3'].validate((valid) => {
    //     if (valid) {
    //       this.loading = true
    //       this.scanCodeForm3.payType = 1
    //       accountRechargeSaoma(this.scanCodeForm3).then((res) => {
    //         console.log(res.data)
    //         this.imageUrl = res.data
    //         this.dialogVisible = true
    //         // this.$message.success('申请成功，请等待管理员审核')
    //         // this.$router.replace('/account/recharge')
    //         this.loading = false
    //       }).catch(err => {
    //         console.warn(err)
    //         this.loading = false
    //       })
    //     }
    //   })
    // },
    payDone() {
      this.dialogVisible = false
      this.$router.replace('/account/recharge')
    },
    uploadSuccess(response) {
      this.uploadLoading = false
      this.uploadPercent = 0
      if (response.code === 200) {
        this.bankApplyForm.attachment = response.data
      } else {
        this.$message.error('上传文件失败!' + response.message)
      }
    },
    beforeUpload(file) {
      const acceptTypes = ['image/png', 'image/jpeg']
      const isJPG = acceptTypes.some(item => item === file.type)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传图片只能是jpg/png格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过2MB')
        return false
      }
      return isJPG && isLt2M
    },
    uploadProcess(file) {
      this.uploadLoading = true
      this.uploadPercent = file.percentage ? +file.percentage.toFixed(0) : 0
    },
    parseNumber(val) {
      return num2string(val)
    },
    toClose() {
      this.$confirm('确认放弃支付并关闭此窗口？')
          .then(() => {
            this.dialogVisible = false
          })
          .catch(() => {
          })
    }
  }
}
</script>

<style lang="scss">
.recharge-apply {
  .el-input {
    width: 280px;
  }

  .pane-upload {
    text-align: left;
  }

  .money {
    font-weight: bold;
  }

  .payments {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    .payment-item {
      display: flex;
      max-width: 200px;
      margin: 0;
      padding-bottom: 6px;

      .el-radio__input {
        display: none;
      }

      .el-radio__label {
        display: flex;
        padding-left: 0;

        img {
          width: 185px;
          height: 52px;
        }
      }
    }

    .p-image {
      position: relative;
      border: 2px solid #F2F2F2;
      margin-right: 6px;
    }

    .active {
      border-color: #266AAD;
    }

    .active:after {
      bottom: 0;
      right: 0;
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 21px solid #266AAD;
      border-left: 21px solid transparent;
    }

    .active:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 5px;
      background: transparent;
      bottom: 4px;
      right: 0;
      border: 2px solid white;
      border-top: none;
      border-right: none;
      -webkit-transform: rotate(-55deg);
      -ms-transform: rotate(-55deg);
      transform: rotate(-55deg);
      z-index: 9;
    }
  }
}
</style>
